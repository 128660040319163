<template>
    <v-dialog v-model="dialog" max-width="500px">
        <v-card>
            <v-card-title>
                <span class="headline">{{ $vuetify.lang.t('$vuetify.editMember') }}</span>
            </v-card-title>
            <v-card-text>
                <v-text-field
                        :label="$vuetify.lang.t('$vuetify.no')"
                        v-model="member.no"
                ></v-text-field>
                <v-text-field
                        :label="$vuetify.lang.t('$vuetify.member_no')"
                        v-model="member.member_no"
                ></v-text-field>
                <v-text-field
                        :label="$vuetify.lang.t('$vuetify.name')"
                        v-model="member.name"
                ></v-text-field>
                <v-text-field
                        :label="$vuetify.lang.t('$vuetify.salutation')"
                        v-model="member.salutation"
                ></v-text-field>
                <v-text-field
                        :label="$vuetify.lang.t('$vuetify.address')"
                        v-model="member.address"
                ></v-text-field>
                <v-text-field
                        :label="$vuetify.lang.t('$vuetify.postal_code')"
                        v-model="member.postal_code"
                ></v-text-field>
                <v-text-field
                        :label="$vuetify.lang.t('$vuetify.city')"
                        v-model="member.city"
                ></v-text-field>
                <v-text-field
                        :label="$vuetify.lang.t('$vuetify.code')"
                        v-model="member.code"
                ></v-text-field>
                <v-text-field
                        :label="$vuetify.lang.t('$vuetify.member')"
                        v-model="member.member"
                ></v-text-field>
                <v-text-field
                        :label="$vuetify.lang.t('$vuetify.member_kids_club')"
                        v-model="member.member_kids_club"
                ></v-text-field>
                <v-text-field
                        :label="$vuetify.lang.t('$vuetify.blocked')"
                        v-model="member.blocked"
                ></v-text-field>
                <v-text-field
                        :label="$vuetify.lang.t('$vuetify.birthday')"
                        v-model="member.birthday"
                ></v-text-field>
                <v-text-field
                        :label="$vuetify.lang.t('$vuetify.date_entry')"
                        v-model="member.date_entry"
                ></v-text-field>
                <v-text-field
                        :label="$vuetify.lang.t('$vuetify.date_exit')"
                        v-model="member.date_exit"
                ></v-text-field>
            </v-card-text>
            <v-card-actions>
                <v-btn v-if="loadingDestroy" color="error" disabled><v-progress-circular size="24" indeterminate></v-progress-circular></v-btn>
                <v-btn v-else color="error" @click="destroy" :disabled="loadingUpdate"><v-icon>mdi-delete</v-icon></v-btn>

                <v-spacer></v-spacer>
                <v-btn color="primary darken-1" text @click="close" :disabled="loadingUpdate || loadingDestroy">{{ $vuetify.lang.t('$vuetify.close') }}</v-btn>
                <v-btn v-if="loadingUpdate" color="primary" disabled><v-progress-circular size="24" indeterminate></v-progress-circular></v-btn>
                <v-btn v-else type="submit" color="primary" @click="update" :disabled="loadingDestroy">{{ $vuetify.lang.t('$vuetify.save') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        name: 'createDialog',
        props:{
            member: Object
        },
        data: () => ({
            loadingUpdate: false,
            loadingDestroy: false,
            dialog: true,
        }),

        methods: {

            update(){
                this.loadingUpdate=true;

                this.$http.put('/api/members/'+this.member.id, this.member)
                    .then(({data}) => {
                        this.$root.notys(data.messages || []);
                        this.member.first_name = data.data.first_name;
                        this.member.last_name = data.data.last_name;
                        this.member.member_no = data.data.member_no;
                    }).catch(error => this.$root.notyError(error.response)).finally(()=>{this.loadingUpdate=false});
            },
            destroy(){
                if(confirm(this.$vuetify.lang.t('$vuetify.destroy'))){
                    this.loadingDestroy=true;
                    this.$http.delete('/api/members/'+this.member.id)
                        .then(({data}) => {
                            this.$root.notys(data.messages || []);
                            this.$parent.$parent.index();
                            this.close();
                        }).catch(error => this.$root.notyError(error.response)).finally(()=>{this.loadingDestroy=false});
                }
            },

            close(){
                this.$parent.$parent.edit=false;
                this.dialog = false;
            }
        }
    }
</script>
