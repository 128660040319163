<template>
    <v-card>

    </v-card>
</template>

<script>
    export default {
        name: 'Dashboard',
        components: {

        },
        data: () => ({
            loading: false,
        }),
        created() {

        },

        methods: {

        }
    }
</script>
