<template>
    <v-card>
        <v-progress-linear v-if="loading" indeterminate></v-progress-linear>
        <v-card-title>
            <span>{{ $vuetify.lang.t('$vuetify.clubs') }}</span>
            <v-spacer></v-spacer>
            <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    :label="$vuetify.lang.t('$vuetify.search')"
                    single-line
                    hide-details
            ></v-text-field>
            <v-spacer></v-spacer>
            <create-club></create-club>
        </v-card-title>

        <v-data-table
                :headers="headers"
                :items="items"
                :search="search"
                :items-per-page="50"
                :footer-props="{
                    'items-per-page-options': [10, 50, 250, -1]
                }"
        >
            <template v-slot:header.name="">
                {{ $vuetify.lang.t('$vuetify.name') }}
            </template>
            <template v-slot:header.stadium="">
                {{ $vuetify.lang.t('$vuetify.stadium') }}
            </template>
            <template v-slot:header.logo="">
                {{ $vuetify.lang.t('$vuetify.logo') }}
            </template>
            <template v-slot:item.logo="{ item }">
                <v-icon v-if="item.logo" color="success">mdi-check-circle</v-icon>
                <v-icon v-else>mdi-close-circle</v-icon>
            </template>
            <template v-slot:item.action="{ item }">
                <v-tooltip left>
                    <template v-slot:activator="{ on }">
                        <v-btn
                                v-on="on"
                                small
                                fab
                                color="primary"
                                @click="edit=item"
                        ><v-icon>mdi-pencil</v-icon></v-btn>
                    </template>
                    <span>{{ $vuetify.lang.t('$vuetify.tooltipEdit') }}</span>
                </v-tooltip>
            </template>
            <v-alert slot="no-results" :value="true" color="error" icon="warning">
                {{ $vuetify.lang.t('$vuetify.dataIterator.noResultsText') }}
            </v-alert>
        </v-data-table>
        <edit-club v-if="edit" :club="edit"></edit-club>
    </v-card>
</template>

<script>
    import createClub from './components/createDialog';
    import editClub from './components/editDialog';

    export default {
        name: 'Clubs',
        components: {
            createClub,
            editClub
        },
        data: () => ({
            items: [],
            loading: false,
            search: null,
            headers: [
                {text: 'Name', align: 'left', sortable: true, value: 'name'},
                {text: 'Stadion', align: 'left', sortable: true, value: 'stadium'},
                {text: 'Logo', align: 'center', sortable: true, value: 'logo'},
                {text: '', align: 'right', sortable: false, value: 'action'}
            ],
            edit: false
        }),
        created() {
            this.index();
        },

        methods: {

            index(){
                this.loading=true;
                this.$http.get('/api/clubs')
                    .then(({data}) => {
                        this.items = data;
                    }).catch(error => this.$root.notyError(error.response)).finally(()=>{this.loading=false});
            }
        }
    }
</script>
