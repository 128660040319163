<template>
    <div class="container">
        <div class="col-md-6 offset-md-3">
           <h1>404</h1>
        </div>
    </div>
</template>

<script>
    export default {
        name: '404',

    }
</script>
