<template>
        <v-row>
            <v-col
                    md="4"
                    sm="6"
                    offset-md="4"
                    offset-sm="3"
            >
                <div class="text-center">
                    <img src="../../assets/bvb-fanabteilung-logo.png" alt="bar" srcset="../../assets/bvb-fanabteilung-logo2x.png 2x" />
                </div>
                <v-card>
                    <v-card-title>
                        <h3 class="headline">{{ $vuetify.lang.t('$vuetify.requestPassword') }}</h3>
                    </v-card-title>
                    <v-card-text>
                        <v-form v-on:submit.prevent.stop="reset">
                            <v-text-field
                                    v-model="password.password"
                                    :label="$vuetify.lang.t('$vuetify.password')"
                                    type="password"
                                    required
                            ></v-text-field>
                            <v-text-field
                                    v-model="password.password_confirmation"
                                    :label="$vuetify.lang.t('$vuetify.passwordConfirmation')"
                                    type="password"
                                    required
                            ></v-text-field>

                            <v-card-actions>
                                <v-btn to="/login" color="primary darken-1" text><v-icon>mdi-arrow-left</v-icon>{{ $vuetify.lang.t('$vuetify.login') }}</v-btn>
                                <v-spacer></v-spacer>
                                <v-btn v-if="loading" color="primary" disabled><v-progress-circular size="24" indeterminate></v-progress-circular></v-btn>
                                <v-btn v-else type="submit" color="primary">{{ $vuetify.lang.t('$vuetify.requestPassword') }}</v-btn>
                            </v-card-actions>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
</template>

<script>

    export default {
        name: 'ResetPassword',
        components: {

        },
        data() {
            return {
                loading: false,
                email: '',
                password: {
                    password: null,
                    password_confirmation: null
                },
            };
        },
        mounted() {

        },

        methods: {
            reset() {
                this.loading=true;
              this.$http.get('/sanctum/csrf-cookie', {withCredentials: true}).then(() => {

                this.$http.put('/api/password/reset', {
                    password: this.password.password,
                    password_confirmation: this.password.password_confirmation,
                    token: this.$route.query.token,
                    email: this.$route.query.email
                }, {withCredentials: true}).then(({data}) => {
                    this.$root.notys(data.messages || []);
                    this.$router.push('/login');
                }).catch(error => this.$root.notyError(error.response)).finally(()=>{this.loading=false});
              })
            }
        }
    }
</script>

<style>

</style>
