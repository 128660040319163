<template>
    <v-card
            flat
            tile
    >
        <v-card-text>
            <v-row>
                <v-col>
                    <v-text-field
                            :label="$vuetify.lang.t('$vuetify.name')"
                            v-model="trip.name"
                    ></v-text-field>
                    <v-autocomplete
                            :label="$vuetify.lang.t('$vuetify.club')"
                            :items="clubs"
                            item-text="name"
                            item-value="id"
                            v-model="trip.club_id"
                    ></v-autocomplete>
                    <v-text-field
                            :label="$vuetify.lang.t('$vuetify.appointment')"
                            v-model="trip.appointment"
                            v-mask="dateTimeMask"
                            :placeholder="dateTimePlaceholder"
                    ></v-text-field>
                    <v-text-field
                            :label="$vuetify.lang.t('$vuetify.visibleAt')"
                            v-model="trip.visible_at"
                            v-mask="dateTimeMask"
                            :placeholder="dateTimePlaceholder"
                    ></v-text-field>
                    <v-text-field
                            :label="$vuetify.lang.t('$vuetify.applicationStart')"
                            v-model="trip.application_start"
                            v-mask="dateTimeMask"
                            :placeholder="dateTimePlaceholder"
                    ></v-text-field>
                    <v-text-field
                            :label="$vuetify.lang.t('$vuetify.applicationDeadline')"
                            v-model="trip.application_deadline"
                            v-mask="dateTimeMask"
                            :placeholder="dateTimePlaceholder"
                    ></v-text-field>
                    <v-text-field
                            :label="$vuetify.lang.t('$vuetify.departure')"
                            v-model="trip.departure"
                            v-mask="dateTimeMask"
                            :placeholder="dateTimePlaceholder"
                    ></v-text-field>
                </v-col>
                <v-col>
                    <v-text-field
                            :label="$vuetify.lang.t('$vuetify.purposeOfPayment')"
                            v-model="trip.purpose_of_payment"
                    ></v-text-field>
                    <v-textarea
                            :label="$vuetify.lang.t('$vuetify.textDecline')"
                            v-model="trip.text_decline"
                    ></v-textarea>
                    <v-textarea
                            class="mb-3"
                            :label="$vuetify.lang.t('$vuetify.textAccept')"
                            v-model="trip.text_accept"
                    ></v-textarea>

                    <v-btn v-if="loadingNotifyApproved" class="mb-2" color="primary" block disabled><v-progress-circular size="24" indeterminate></v-progress-circular></v-btn>
                    <v-btn v-else type="button" class="mb-2" color="primary" block @click="notifyApproved" :disabled="loadingDestroy || loading">{{ $vuetify.lang.t('$vuetify.notifyApproved') }}</v-btn>

                    <v-btn v-if="loadingNotifyDecline" class="mb-2" color="primary" block disabled><v-progress-circular size="24" indeterminate></v-progress-circular></v-btn>
                    <v-btn v-else type="button" color="primary" block @click="notifyDeclined" :disabled="loadingDestroy || loading">{{ $vuetify.lang.t('$vuetify.notifyDeclined') }}</v-btn>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions>
            <v-btn v-if="loadingDestroy" color="error" disabled><v-progress-circular size="24" indeterminate></v-progress-circular></v-btn>
            <v-tooltip top v-else>
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" color="error" @click="destroy" :disabled="loading"><v-icon>mdi-delete</v-icon></v-btn>
                </template>
                <span>{{ $vuetify.lang.t('$vuetify.tooltipDestroy') }}</span>
            </v-tooltip>

            <v-spacer></v-spacer>
            <v-btn v-if="loading" color="primary" disabled><v-progress-circular size="24" indeterminate></v-progress-circular></v-btn>
            <v-btn v-else type="submit" color="primary" @click="update" :disabled="loadingDestroy">{{ $vuetify.lang.t('$vuetify.save') }}</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    export default {
        name: 'Global',
        components: {

        },
        props: {
            trip: Object
        },
        data: () => ({
            clubs: [],
            loading: false,
            loadingNotifyApproved: false,
            loadingNotifyDecline: false,
            loadingDestroy: false,
            dateTimeMask: '##.##.#### ##:##',
            dateTimePlaceholder: '19.12.1909 19:09'
        }),
        created() {
            this.clubsIndex();
        },

        methods: {

            update(){
                this.loading=true;

                this.$http.put('/api/trips/'+this.$route.params.id, this.trip)
                    .then(({data}) => {
                        this.trip = data.data;
                    }).catch(error => this.$root.notyError(error.response)).finally(()=>{this.loading=false});
            },
            destroy(){
                if(confirm(this.$vuetify.lang.t('$vuetify.destroy'))){
                    this.loadingDestroy=true;
                    this.$http.delete('/api/trips/'+this.club.id)
                        .then(({data}) => {
                            this.$root.notys(data.messages || []);
                            this.$router.push('/trips');
                            this.close();
                        }).catch(error => this.$root.notyError(error.response)).finally(()=>{this.loadingDestroy=false});
                }
            },
            notifyApproved(){
                this.loadingNotifyApproved=true;
                this.$http.post('/api/trips/'+this.$route.params.id+'/applications/notify/approved')
                    .then(({data}) => {
                        this.trip = data;
                    }).catch(error => this.$root.notyError(error.response)).finally(()=>{this.loadingNotifyApproved=false});
            },
            notifyDeclined(){
                this.loadingNotifyDecline=true;
                this.$http.post('/api/trips/'+this.$route.params.id+'/applications/notify/declined')
                    .then(({data}) => {
                        this.trip = data;
                    }).catch(error => this.$root.notyError(error.response)).finally(()=>{this.loadingNotifyDecline=false});
            },
            clubsIndex(){
                this.loading=true;
                this.$http.get('/api/clubs')
                    .then(({data}) => {
                        this.clubs = data;
                    }).catch(error => this.$root.notyError(error.response)).finally(()=>{this.loading=false});
            }
        }
    }
</script>
