<template>
    <v-card
            flat
            tile
    >
        <v-progress-linear v-if="loading" indeterminate></v-progress-linear>
        <v-simple-table>
            <template v-slot:default>
                <thead>
                <tr>
                    <th>{{ $vuetify.lang.t('$vuetify.name') }}</th>
                    <th class="text-right">{{ $vuetify.lang.t('$vuetify.price') }}</th>
                    <th class="text-right">{{ $vuetify.lang.t('$vuetify.priceMember') }}</th>
                    <th class="text-right">
                        <create-ticket></create-ticket>
                    </th>
                </tr>
                </thead>
                <draggable v-model="items" group="tickets" @start="dragging=true" @end="updatePositions" tag="tbody">
                    <tr v-for="item in items" v-bind:key="item.id" :class="dragging?'dragging draggable':'draggable'">
                        <td>{{item.name}}</td>
                        <td class="text-right">{{ $root.formatCurrency.format(item.price) }}</td>
                        <td class="text-right">{{ $root.formatCurrency.format(item.price_member) }}</td>
                        <td class="text-right">
                            <v-tooltip left>
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                            v-on="on"
                                            small
                                            fab
                                            color="primary"
                                            @click="edit = item"
                                            :disabled="dragging"
                                    ><v-icon>mdi-pencil</v-icon></v-btn>
                                </template>
                                <span>{{ $vuetify.lang.t('$vuetify.tooltipEdit') }}</span>
                            </v-tooltip>
                        </td>
                    </tr>
                </draggable>
            </template>
        </v-simple-table>
        <edit-ticket v-if="edit" :ticket="edit"></edit-ticket>
    </v-card>
</template>

<script>
    import draggable from 'vuedraggable'

    import createTicket from './components/createDialog';
    import editTicket from './components/editDialog';

    export default {
        name: 'Tickets',
        components: {
            createTicket,
            editTicket,
            draggable
        },
        data: () => ({
            items: [],
            loading: false,
            loadingDestroy: false,
            edit: false,
            dragging: false
        }),
        created() {
            this.index();
        },

        methods: {

            index(){
                this.loading=true;
                this.$http.get('/api/trips/'+this.$route.params.id+'/tickets')
                    .then(({data}) => {
                        this.items = data;
                    }).catch(error => this.$root.notyError(error.response)).finally(()=>{this.loading=false});
            },
            updatePositions(){
                this.loading=true;

                this.$http.put('/api/trips/'+this.$route.params.id+'/tickets/position', {tickets: this.items})
                    .then(({data}) => {
                        this.$root.notys(data.messages || []);
                    }).catch(error => this.$root.notyError(error.response)).finally(()=>{
                        this.loading=false;
                        this.dragging=false;
                    });
            }
        }
    }
</script>
