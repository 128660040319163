<template>
    <v-card>
        <v-progress-linear v-if="loading" indeterminate></v-progress-linear>
        <v-card-title>
            <span>{{ $vuetify.lang.t('$vuetify.trips') }}</span>
            <v-spacer></v-spacer>
            <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    :label="$vuetify.lang.t('$vuetify.search')"
                    single-line
                    hide-details
            ></v-text-field>
            <v-spacer></v-spacer>
            <create-trip></create-trip>
        </v-card-title>

        <v-data-table
                :headers="headers"
                :items="items"
                :search="search"
                :items-per-page="50"
                :footer-props="{
                    'items-per-page-options': [10, 50, 250, -1]
                }"
        >
            <template v-slot:header.name="">
                {{ $vuetify.lang.t('$vuetify.name') }}
            </template>
            <template v-slot:header.club.name="">
                {{ $vuetify.lang.t('$vuetify.club') }}
            </template>
            <template v-slot:header.appointment="">
                {{ $vuetify.lang.t('$vuetify.appointment') }}
            </template>
            <template v-slot:header.visible_at="">
                {{ $vuetify.lang.t('$vuetify.visibleAt') }}
            </template>

            <template v-slot:item.appointment="{ item }">
                {{ item.appointment }}
            </template>
            <template v-slot:item.visible_at="{ item }">
                {{ item.visible_at }}
            </template>

            <template v-slot:item.action="{ item }">

                <v-tooltip left>
                    <template v-slot:activator="{ on }">
                        <v-btn
                                v-on="on"
                                small
                                fab
                                color="primary"
                                :to="'/trips/'+item.id"
                        ><v-icon>mdi-pencil</v-icon></v-btn>
                    </template>
                    <span>{{ $vuetify.lang.t('$vuetify.tooltipEdit') }}</span>
                </v-tooltip>

            </template>
            <v-alert slot="no-results" :value="true" color="error" icon="warning">
                {{ $vuetify.lang.t('$vuetify.dataIterator.noResultsText') }}
            </v-alert>
        </v-data-table>
    </v-card>
</template>

<script>
    import createTrip from './components/createDialog';

    export default {
        name: 'Trips',
        components: {
            createTrip
        },
        data: () => ({
            items: [],
            loading: false,
            search: null,
            headers: [
                {text: 'Name', align: 'left', sortable: true, value: 'name'},
                {text: 'Verein', align: 'left', sortable: true, value: 'club.name'},
                {text: 'Datum', align: 'right', sortable: true, value: 'appointment'},
                {text: 'Sichtbar', align: 'right', sortable: true, value: 'visible_at'},
                {text: '', align: 'right', sortable: false, value: 'action'}
            ]
        }),
        created() {
            this.index();
        },

        methods: {

            index(){
                this.loading=true;
                this.$http.get('/api/trips')
                    .then(({data}) => {
                        this.items = data;
                    }).catch(error => this.$root.notyError(error.response)).finally(()=>{this.loading=false});
            }
        }
    }
</script>
