<template>
    <v-card>
        <v-progress-linear v-if="loading" indeterminate></v-progress-linear>
        <v-card-title>
            <span>{{ $vuetify.lang.t('$vuetify.navigation.settings') }}</span>
            <v-spacer></v-spacer>
        </v-card-title>

        <v-tabs
                v-model="tab"
        >
            <v-tabs-slider></v-tabs-slider>

            <v-tab href="#tab-account">
                {{ $vuetify.lang.t('$vuetify.account') }}
            </v-tab>
            <v-tab href="#tab-admins">
                {{ $vuetify.lang.t('$vuetify.admins') }}
            </v-tab>

            <v-tab-item value="tab-account">
                <v-card
                        flat
                        tile
                >
                    <settings-account></settings-account>
                </v-card>
            </v-tab-item>
            <v-tab-item value="tab-admins">
                <v-card
                        flat
                        tile
                >
                    <settings-admin></settings-admin>
                </v-card>
            </v-tab-item>
        </v-tabs>


    </v-card>
</template>

<script>
    import settingsAccount from './components/Account/index';
    import settingsAdmin from './components/Admins/index';

    export default {
        name: 'Settings',
        components: {
            settingsAccount,
            settingsAdmin
        },
        data: () => ({
            loading: false,
            tab: null
        }),
        created() {

        },

        methods: {


        }
    }
</script>
