<template>
    <v-dialog v-model="dialog" max-width="800px">
        <v-card>
            <v-progress-linear v-if="loading" indeterminate></v-progress-linear>
            <v-card-title>
                <span class="headline">{{ $vuetify.lang.t('$vuetify.editUser') }}</span>
            </v-card-title>
            <v-card-text>
                <v-text-field
                        :label="$vuetify.lang.t('$vuetify.email')"
                        v-model="user.email"
                        readonly
                ></v-text-field>
                <v-text-field
                        :label="$vuetify.lang.t('$vuetify.createdAt')"
                        v-model="user.created_at"
                        readonly
                ></v-text-field>
            </v-card-text>
            <v-card-actions>
                <v-btn v-if="loadingActivate" color="error" disabled class="mr-2"><v-progress-circular size="24" indeterminate></v-progress-circular></v-btn>
                <v-tooltip top v-else>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" color="error" @click="destroy" :disabled="loadingDestroy || loading" class="mr-2"><v-icon>mdi-delete</v-icon></v-btn>
                    </template>
                    <span>{{ $vuetify.lang.t('$vuetify.tooltipDestroy') }}</span>
                </v-tooltip>


                <template v-if="user.deleted_at">
                    <v-btn v-if="loadingDestroy" color="warning" disabled><v-progress-circular size="24" indeterminate></v-progress-circular></v-btn>
                    <v-btn v-else color="warning" @click="activate" :disabled="loadingActivate || loading">{{ $vuetify.lang.t('$vuetify.activate') }}</v-btn>

                </template>
                <template v-else>
                    <v-btn v-if="loadingDestroy" color="warning" disabled><v-progress-circular size="24" indeterminate></v-progress-circular></v-btn>
                    <v-btn v-else color="warning" @click="deactivate" :disabled="loadingActivate || loading">{{ $vuetify.lang.t('$vuetify.deactivate') }}</v-btn>

                </template>

                <v-spacer></v-spacer>
                <v-btn color="primary darken-1" text @click="close" :disabled="loadingActivate || loadingDestroy">{{ $vuetify.lang.t('$vuetify.close') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        name: 'createDialog',
        props:{
            userId: [Number, String]
        },
        data: () => ({
            loadingDestroy: false,
            loadingActivate: false,
            loading: false,
            dialog: true,
            user: {
                email: null,
                created_at: null,
                deleted_at: null
            }
        }),

        created(){
            this.show();
        },
        methods: {

            show(){
                this.loading=true;

                this.$http.get('/api/users/'+this.userId)
                    .then(({data}) => {
                        this.$root.notys(data.messages || []);
                        this.user = data;
                    }).catch(error => this.$root.notyError(error.response)).finally(()=>{this.loading=false});
            },
            destroy(){
                if(confirm(this.$vuetify.lang.t('$vuetify.destroy'))){
                    this.loadingDestroy=true;
                    this.$http.delete('/api/users/'+this.user.id)
                        .then(({data}) => {
                            this.$root.notys(data.messages || []);
                            this.$parent.$parent.index();
                            this.close();
                        }).catch(error => this.$root.notyError(error.response)).finally(()=>{this.loadingDestroy=false});
                }
            },
            activate(){
                if(confirm(this.$vuetify.lang.t('$vuetify.confirmActivate'))){
                    this.loadingActivate=true;
                    this.$http.put('/api/users/'+this.user.id+'/activate')
                        .then(({data}) => {
                            this.$root.notys(data.messages || []);
                            this.user = data.data;
                            this.$parent.$parent.index();
                        }).catch(error => this.$root.notyError(error.response)).finally(()=>{this.loadingActivate=false});
                }
            },
            deactivate(){
                if(confirm(this.$vuetify.lang.t('$vuetify.confirmDeactivate'))){
                    this.loadingActivate=true;
                    this.$http.put('/api/users/'+this.user.id+'/deactivate')
                        .then(({data}) => {
                            this.$root.notys(data.messages || []);
                            this.user = data.data;
                            this.$parent.$parent.index();
                        }).catch(error => this.$root.notyError(error.response)).finally(()=>{this.loadingActivate=false});
                }
            },
            close(){
                this.$parent.$parent.edit=false;
                this.dialog = false;
            }
        }
    }
</script>
