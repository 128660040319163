<template>
    <v-dialog v-model="dialog" max-width="500px">
        <template v-slot:activator="{ on: dialog }">
            <v-tooltip left>
                <template v-slot:activator="{ on: tooltip }">
                    <v-btn
                            v-on="{ ...dialog, ...tooltip }"
                            fab
                            small
                            color="primary"
                            class="mr-1"
                    >
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </template>
                <span>
                {{ $vuetify.lang.t('$vuetify.tooltipCreateMember') }}
            </span>
            </v-tooltip>
        </template>
        <v-card>
            <v-card-title>
                <span class="headline">{{ $vuetify.lang.t('$vuetify.createMember') }}</span>
            </v-card-title>
            <v-card-text>
                <v-text-field
                        :label="$vuetify.lang.t('$vuetify.no')"
                        v-model="member.no"
                ></v-text-field>
                <v-text-field
                        :label="$vuetify.lang.t('$vuetify.member_no')"
                        v-model="member.member_no"
                ></v-text-field>
                <v-text-field
                        :label="$vuetify.lang.t('$vuetify.name')"
                        v-model="member.name"
                ></v-text-field>
                <v-text-field
                        :label="$vuetify.lang.t('$vuetify.salutation')"
                        v-model="member.salutation"
                ></v-text-field>
                <v-text-field
                        :label="$vuetify.lang.t('$vuetify.address')"
                        v-model="member.address"
                ></v-text-field>
                <v-text-field
                        :label="$vuetify.lang.t('$vuetify.postal_code')"
                        v-model="member.postal_code"
                ></v-text-field>
                <v-text-field
                        :label="$vuetify.lang.t('$vuetify.city')"
                        v-model="member.city"
                ></v-text-field>
                <v-text-field
                        :label="$vuetify.lang.t('$vuetify.code')"
                        v-model="member.code"
                ></v-text-field>
                <v-text-field
                        :label="$vuetify.lang.t('$vuetify.member')"
                        v-model="member.member"
                ></v-text-field>
                <v-text-field
                        :label="$vuetify.lang.t('$vuetify.member_kids_club')"
                        v-model="member.member_kids_club"
                ></v-text-field>
                <v-text-field
                        :label="$vuetify.lang.t('$vuetify.blocked')"
                        v-model="member.blocked"
                ></v-text-field>
                <v-text-field
                        :label="$vuetify.lang.t('$vuetify.birthday')"
                        v-model="member.birthday"
                ></v-text-field>
                <v-text-field
                        :label="$vuetify.lang.t('$vuetify.date_entry')"
                        v-model="member.date_entry"
                ></v-text-field>
                <v-text-field
                        :label="$vuetify.lang.t('$vuetify.date_exit')"
                        v-model="member.date_exit"
                ></v-text-field>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary darken-1" text @click="close">{{ $vuetify.lang.t('$vuetify.close') }}</v-btn>
                <v-btn v-if="loading" color="primary" disabled><v-progress-circular size="24" indeterminate></v-progress-circular></v-btn>
                <v-btn v-else type="submit" color="primary" @click="store">{{ $vuetify.lang.t('$vuetify.save') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        name: 'createDialog',
        data: () => ({
            loading: false,
            dialog: false,
            member: {
                no: null,
                member_no: null,
                name: null,
                salutation: null,
                address: null,
                postal_code: null,
                city: null,
                code: null,
                member: null,
                member_kids_club: null,
                blocked: null,
                birthday: null,
                date_entry: null,
                date_exit: null
            }
        }),

        methods: {

            store(){
                this.loading=true;

                this.$http.post('/api/members', this.member)
                    .then(({data}) => {
                        this.$parent.$parent.index();
                        this.$root.notys(data.messages);
                        this.close();
                    }).catch(error => this.$root.notyError(error.response)).finally(()=>{this.loading=false});
            },

            close(){
                this.member.first_name=null;
                this.member.last_name=null;
                this.member.member_no=null;
                this.dialog=false;
            }
        }
    }
</script>
