<template>
    <v-dialog v-model="dialog" max-width="500px">

        <template v-slot:activator="{ on: dialog }">
            <v-tooltip left>
                <template v-slot:activator="{ on: tooltip }">
                    <v-btn
                            v-on="{ ...dialog, ...tooltip }"
                            fab
                            small
                            color="primary"
                    >
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </template>
                <span>
                {{ $vuetify.lang.t('$vuetify.tooltipCreateTrip') }}
            </span>
            </v-tooltip>
        </template>

        <v-card>
            <v-card-title>
                <span class="headline">{{ $vuetify.lang.t('$vuetify.createTrip') }}</span>
            </v-card-title>
            <v-card-text>
                <v-text-field
                        :label="$vuetify.lang.t('$vuetify.name')"
                        v-model="trip.name"
                ></v-text-field>
                <v-autocomplete
                        :label="$vuetify.lang.t('$vuetify.club')"
                        :items="clubs"
                        item-text="name"
                        item-value="id"
                        v-model="trip.club_id"
                ></v-autocomplete>
                <v-text-field
                        :label="$vuetify.lang.t('$vuetify.appointment')"
                        v-model="trip.appointment"
                        v-mask="dateTimeMask"
                        :placeholder="dateTimePlaceholder"
                ></v-text-field>
                <v-text-field
                        :label="$vuetify.lang.t('$vuetify.visibleAt')"
                        v-model="trip.visible_at"
                        v-mask="dateTimeMask"
                        :placeholder="dateTimePlaceholder"
                ></v-text-field>
                <v-text-field
                        :label="$vuetify.lang.t('$vuetify.applicationStart')"
                        v-model="trip.application_start"
                        v-mask="dateTimeMask"
                        :placeholder="dateTimePlaceholder"
                ></v-text-field>
                <v-text-field
                        :label="$vuetify.lang.t('$vuetify.applicationDeadline')"
                        v-model="trip.application_deadline"
                        v-mask="dateTimeMask"
                        :placeholder="dateTimePlaceholder"
                ></v-text-field>
                <v-text-field
                        :label="$vuetify.lang.t('$vuetify.departure')"
                        v-model="trip.departure"
                        v-mask="dateTimeMask"
                        :placeholder="dateTimePlaceholder"
                ></v-text-field>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary darken-1" text @click="close">{{ $vuetify.lang.t('$vuetify.close') }}</v-btn>
                <v-btn v-if="loading" color="primary" disabled><v-progress-circular size="24" indeterminate></v-progress-circular></v-btn>
                <v-btn v-else type="submit" color="primary" @click="store">{{ $vuetify.lang.t('$vuetify.save') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        name: 'createDialog',
        data: () => ({
            loading: false,
            dialog: false,
            trip: {
                name: null,
                club_id: null,
                appointment: null,
                visible_at: null,
                application_start: null,
                application_deadline: null,
                departure: null,
            },
            clubs: [],
            dateTimeMask: '##.##.#### ##:##',
            dateTimePlaceholder: '19.12.1909 19:09'
        }),

        created(){
            this.clubsIndex()
        },

        methods: {

            clubsIndex(){
                this.loading=true;
                this.$http.get('/api/clubs')
                    .then(({data}) => {
                        this.clubs = data;
                    }).catch(error => this.$root.notyError(error.response)).finally(()=>{this.loading=false});
            },

            store(){
                this.loading=true;

                this.$http.post('/api/trips', this.trip)
                    .then(({data}) => {
                        this.$router.push('/trips/'+data.data.id);

                    }).catch(error => this.$root.notyError(error.response)).finally(()=>{this.loading=false});
            },

            close(){
                this.trip.name=null;
                this.dialog=false;
            }
        }
    }
</script>
