<template>
    <v-card>
        <v-card-title>
            <v-tooltip right>
                <template v-slot:activator="{ on }">
                    <v-btn
                            v-on="on"
                            to="/trips"
                            small
                            fab
                            text
                            color="primary"
                            class="mr-2"
                    ><v-icon>mdi-arrow-left</v-icon></v-btn>
                </template>
                <span>{{ $vuetify.lang.t('$vuetify.tooltipBack') }}</span>
            </v-tooltip>
            <span><small>{{ $vuetify.lang.t('$vuetify.navigation.settings') }}</small> {{ trip.name }}</span>
            <v-spacer></v-spacer>
        </v-card-title>

        <v-tabs
                v-model="tab"
        >
            <v-tabs-slider></v-tabs-slider>

            <v-tab href="#tab-global">
                {{ $vuetify.lang.t('$vuetify.global') }}
            </v-tab>
            <v-tab href="#tab-description">
                {{ $vuetify.lang.t('$vuetify.description') }}
            </v-tab>
            <v-tab href="#tab-tickets">
                {{ $vuetify.lang.t('$vuetify.tickets') }}
            </v-tab>
            <v-tab href="#tab-applications">
                {{ $vuetify.lang.t('$vuetify.applications') }}
            </v-tab>

            <v-tab-item value="tab-global">
                <trip-global :trip="trip"></trip-global>
            </v-tab-item>
            <v-tab-item value="tab-description">
                <trip-description></trip-description>
            </v-tab-item>
            <v-tab-item value="tab-tickets">
                <trip-tickets></trip-tickets>
            </v-tab-item>
            <v-tab-item value="tab-applications">
                <trip-applications></trip-applications>
            </v-tab-item>
        </v-tabs>
    </v-card>
</template>

<script>
    import tripGlobal from './components/Global/index';
    import tripDescription from './components/Description/index';
    import tripTickets from './components/Tickets/index';
    import tripApplications from './components/Applications/index';

    export default {
        name: 'Trip',
        components: {
            tripGlobal,
            tripDescription,
            tripTickets,
            tripApplications
        },
        data: () => ({
            tab: null,
            trip: {
                name: null
            }
        }),
        created() {
            this.index();
        },

        methods: {
            index(){
                this.loading=true;
                this.$http.get('/api/trips/'+this.$route.params.id)
                    .then(({data}) => {
                        this.trip = data;
                    }).catch(error => this.$root.notyError(error.response)).finally(()=>{this.loading=false});
            },

        }
    }
</script>
