export default {
    close: 'Schließen',
    save: 'Speichern',
    dataIterator: {
        noResultsText: 'Keine Elemente gefunden',
        loadingText: 'Lade Elemente...',
    },
    dataTable: {
        itemsPerPageText: 'Zeilen pro Seite:',
        ariaLabel: {
            sortDescending: ': Absteigend sortiert. Aktivieren um Sortierung zu entfernen.',
            sortAscending: ': Aufsteigend sortiert. Aktivieren um absteigend zu sortieren.',
            sortNone: ': Nicht sortiert. Aktivieren um aufsteigend zu sortieren.',
        },
        sortBy: 'Sortiere nach',
    },
    dataFooter: {
        itemsPerPageText: 'Elemente pro Seite:',
        itemsPerPageAll: 'Alle',
        nextPage: 'Nächste Seite',
        prevPage: 'Vorherige Seite',
        firstPage: 'Erste Seite',
        lastPage: 'Letzte Seite',
        pageText: '{0}-{1} von {2}',
    },
    datePicker: {
        itemsSelected: '{0} ausgewählt',
    },
    noDataText: 'Keine Daten vorhanden',
    carousel: {
        prev: 'Vorheriges Bild',
        next: 'Nächstes Bild',
    },
    calendar: {
        moreEvents: '{0} mehr',
    },
    fileInput: {
        counter: '{0} Dateien',
        counterSize: '{0} Dateien ({1} gesamt)',
    },
    timePicker: {
        am: 'AM',
        pm: 'PM',
    },

    globalError: 'Es gab einen unbekannten Fehler.',

    navigation: {
        clubs: 'Vereine',
        members: 'Mitglieder',
        users: 'Benutzer',
        trips: 'Fahrten',
        settings: 'Einstellungen'
    },

    search: 'Suchen',
    destroy: 'Wirklich löschen?',
    confirmActivate: 'Wirklich aktivieren?',
    confirmDeactivate: 'Wirklich deaktivieren?',

    active: 'Aktiv',
    activate: 'Aktivieren',
    deactivate: 'Deaktivieren',

    admin: 'Admin',
    portal: 'Buchungsportal',
    login: 'Login',
    email: 'E-Mail-Adresse',
    password: 'Passwort',
    passwordConfirmation: 'Passwort wiederholen',

    forgetPassword: 'Passwort vergessen?',
    forgetPasswordHelpText: 'Falls Sie Ihr Passwort vergessen haben, geben Sie Ihre E-Mail-Adresse in das unten stehende Feld ein und wir senden Ihnen einen Link zum zurücksetzten zu.',
    requestPassword: 'Passwort zurücksetzen',

    name: 'Name',
    clubs: 'Vereine',
    firstname: 'Vorname',
    lastname: 'Nachname',
    stadium: 'Stadion',
    logo: 'Logo',
    uploadLogo: 'Logo-Datei auswählen (.svg, .jpg oder .png)',
    createClub: 'Verein anlegen',
    editClub: 'Verein bearbeiten',

    account: 'Mein Account',

    admins: 'Benutzer',
    createAdmin: 'Admin alegen',

    members: 'Mitglieder',
    createMember: 'Mitglied alegen',
    editMember: 'Mitglied bearbeiten',
    first_name: 'Vorame',
    last_name: 'Nachname',
    member_no: 'Mitglieds-Nr.',
    uploadMembers: 'Mitgliederliste hochladen',
    chooseFile: 'Datei wählen (.csv, .xls oder .xlsx)',

    users: 'Benutzerkonten',
    editUser: 'Benutzerkonto anzeigen',
    createdAt: 'Erstellt am',

    trips: 'Fahrten',
    createTrip: 'Neue Fahrt erstellen',
    club: 'Verein',
    appointment: 'Datum',
    visibleAt: 'Sichtbar ab',
    applicationStart: 'Bewerbungsbegin',
    applicationDeadline: 'Bewerbungsende',
    departure: 'Abfahrt',

    global: 'Global',
    description: 'Beschreibung',
    tickets: 'Tickets',
    applications: 'Bewerbungen',

    textDecline: 'E-Mail-Text für Absage',
    textAccept: 'E-Mail-Text (letzter Absatz) für Zusage',

    purposeOfPayment: 'Verwendungszweck',

    createTicket: 'Ticket erstellen',
    editTicket: 'Ticket bearbeiten',
    price: 'Preis',
    priceMember: 'Preis für Mitglieder',

    nr: 'Nummer',
    no: 'Nummer',
    salutation: 'Anrede',
    code: 'Code',
    member: 'Mitglied',
    member_kids_club: 'Mitglied Kids Club',
    address: 'Adresse',
    phone: 'Telefonnummer',
    postal_code: 'Postleitzahl',
    city: 'Ort',
    status: 'Status',
    blocked: 'Blockiert',
    date_entry: 'Eintritt',
    date_exit: 'Austritt',
    birthday: 'Geburtstag',
    ticket_id: 'Ticket',
    ticket: 'Ticket',

    editApplication: 'Bewerbung bearbeiten',

    notifyApproved: 'Annahmen versenden',
    notifyDeclined: 'Absagen versenden',

    importApplications: 'Bewerbungen importieren',
    import: 'Importieren',

    tooltipCreateClub: 'Verein hinzufügen',
    tooltipNotify: 'Fan benachrichtigen',
    tooltipBack: 'Zurück',
    tooltipEdit: 'Bearbeiten',
    tooltipDestroy: 'Löschen',
    tooltipDestroyImage: 'Bild löschen',
    tooltipUploadMembers: 'Mitgliederliste hochladen',
    tooltipCreateMember: 'Mitglied hinzufügen',
    tooltipCreateTrip: 'Fahrt hinzufügen',
    tooltipApplicationAccept: 'Markierte Bewerbungen akzeptieren',
    tooltipApplicationDecline: 'Markierte Bewerbungen ablehnen',
    tooltipApplicationDownload: 'Alle Bewerbungen herunterladen',
    tooltipApplicationUpload: 'Bewerbungsliste hochladen',
    tooltipCreateAdmin: 'Administrator hinzufügen'
}
