import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import VueRouter from 'vue-router';
import Axios from 'axios'
import VueNoty from 'vuejs-noty';
import VueTheMask from 'vue-the-mask'

Vue.use(VueRouter);
Vue.use(VueNoty, {
    theme: 'metroui'
});
Vue.use(VueTheMask);

Axios.defaults.withCredentials = true
const instance = Axios.create({
    baseURL: 'https://app.buchung.fanabteilung.bvb.de',
    //baseURL: 'http://bvb-buchungsportal-api.test/api',
    headers: {
        'Content-Type': 'application/json',
        'Accept-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
    }
});



Vue.config.productionTip = false;
Vue.config.devtools = true;
Vue.prototype.$http = instance;

const routes = [
    { path: '*', component: require('./components/404').default, meta: {}},
    { path: '/login', component: require('./components/Login/login').default },
    { path: '/password/reset', component: require('./components/Login/passwordReset').default },

    { path: '/', component: require('./components/Dashboard/index').default, meta: { middlewareAuth: true } },
    { path: '/settings', component: require('./components/Settings/index').default, meta: { middlewareAuth: true } },

    { path: '/clubs', component: require('./components/Clubs/index').default, meta: { middlewareAuth: true } },

    { path: '/members', component: require('./components/Members/index').default, meta: { middlewareAuth: true } },

    { path: '/users', component: require('./components/Users/index').default, meta: { middlewareAuth: true } },

    { path: '/trips', component: require('./components/Trips/index').default, meta: { middlewareAuth: true } },
    { path: '/trips/:id', component: require('./components/Trips/show').default, meta: { middlewareAuth: true } },
];

const router = new VueRouter({
    mode: 'history',
    routes
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.middlewareAuth)) {

        if (!localStorage.getItem('user')) {
            next({
                path: '/login',
                query: { redirect: to.fullPath }
            });

            return;
        }
    }

    next();
});

const currency = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 2
});


new Vue({
    router,
    vuetify,
    currency,
    render: h => h(App),
    data:{
        user: null,
        appUrl: 'https://app.bvb.dev2g.de/',
        //appUrl: 'http://bvb-buchungsportal-api.test/',
        formatCurrency: currency
    },
    created(){
        this.rememberAuth();
    },
    methods:{

        rememberAuth(){
            let userData = window.localStorage.getItem('user');

            if(userData){
                this.user = userData ? JSON.parse(userData) : null;
            }
        },

        login (user) {
            window.localStorage.setItem('user', JSON.stringify(user));
            this.user = user;
        },

        logout () {
            window.localStorage.clear();
            this.user = null;
            this.$router.push('/login');
        },
        auth(){
            return !! this.user;
        },
        notyError(response){

            if(response){
                if(response.status==400){
                    for(var errorField in response.data){
                        for(var errorMessage in response.data[errorField]){
                            this.$noty.error(response.data[errorField][errorMessage]);
                        }
                    }
                }
                else {
                    this.$noty.error('Es gab einen unbekannten Fehler!');
                }
            } else {
                this.$noty.error('Es gab einen unbekannten Fehler!');
            }
        },
        notys(messages){
            for(var message in messages){
                this.$noty.success(messages[message]);
            }
        }
    },
    watch: {
        user: function (val) {
            this.user = val;
        }
    }
}).$mount('#app')
