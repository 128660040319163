<template>
    <v-dialog v-model="dialog" max-width="700px">
        <v-card>
            <v-card-title>
                <span class="headline">{{ $vuetify.lang.t('$vuetify.editApplication') }}</span>
            </v-card-title>
            <v-card-text>

                <v-select
                        :label="$vuetify.lang.t('$vuetify.ticket_id')"
                        v-model="application.ticket_id"
                        :items="tickets"
                        item-text="name"
                        item-value="id"
                ></v-select>

                <v-expansion-panels>
                    <v-expansion-panel
                            v-for="member in application.members"
                            :key="member.id"
                    >
                        <v-expansion-panel-header>{{ member.name }}</v-expansion-panel-header>
                        <v-expansion-panel-content>

                            <v-text-field
                                    :label="$vuetify.lang.t('$vuetify.email')"
                                    v-model="member.email"
                            ></v-text-field>
                            <v-text-field
                                    :label="$vuetify.lang.t('$vuetify.firstname')"
                                    v-model="member.firstname"
                            ></v-text-field>
                            <v-text-field
                                    :label="$vuetify.lang.t('$vuetify.lastname')"
                                    v-model="member.lastname"
                            ></v-text-field>
                            <v-text-field
                                    :label="$vuetify.lang.t('$vuetify.birthday')"
                                    v-model="member.birthday"
                            ></v-text-field>
                            <v-text-field
                                    :label="$vuetify.lang.t('$vuetify.address')"
                                    v-model="member.address"
                            ></v-text-field>
                            <v-text-field
                                    :label="$vuetify.lang.t('$vuetify.postal_code')"
                                    v-model="member.postal_code"
                            ></v-text-field>
                            <v-text-field
                                    :label="$vuetify.lang.t('$vuetify.city')"
                                    v-model="member.city"
                            ></v-text-field>
                            <v-text-field
                                    :label="$vuetify.lang.t('$vuetify.phone')"
                                    v-model="member.phone"
                            ></v-text-field>
                            <v-text-field
                                    :label="$vuetify.lang.t('$vuetify.member_no')"
                                    v-model="member.member_no"
                            ></v-text-field>
                            <v-text-field
                                    :label="$vuetify.lang.t('$vuetify.club')"
                                    v-model="member.club"
                            ></v-text-field>
                            <v-text-field
                                    :label="$vuetify.lang.t('$vuetify.ticket')"
                                    v-model="member.ticket.name"
                            ></v-text-field>

                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary darken-1" text @click="close">{{ $vuetify.lang.t('$vuetify.close') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        name: 'editDialog',
        props:{
            application: Object,
        },
        data: () => ({
            loading: false,
            loadingDestroy: false,
            dialog: true,
            tickets: []
        }),

        created(){
            this.indexTickets();
        },

        methods: {

            update(){
                this.loading=true;

                this.$http.put('/api/trips/'+this.$route.params.id+'/applications/'+this.user.id, this.user)
                    .then(({data}) => {
                        this.$root.notys(data.messages || []);
                        this.user = data.data;

                    }).catch(error => this.$root.notyError(error.response)).finally(()=>{this.loading=false});
            },
            destroy(){
                if(confirm(this.$vuetify.lang.t('$vuetify.destroy'))){
                    this.loadingDestroy=true;
                    this.$http.delete('/api/trips/'+this.$route.params.id+'/applications/'+this.user.id)
                        .then(({data}) => {
                            this.$root.notys(data.messages || []);
                            this.$parent.$parent.index();
                            this.close();
                        }).catch(error => this.$root.notyError(error.response)).finally(()=>{this.loadingDestroy=false});
                }
            },
            indexTickets(){
                this.loading=true;
                this.$http.get('/api/trips/'+this.$route.params.id+'/tickets')
                    .then(({data}) => {
                        this.tickets = data;
                    }).catch(error => this.$root.notyError(error.response)).finally(()=>{this.loading=false});
            },

            close(){
                this.$parent.$parent.edit=false;
                this.dialog = false;
            }
        }
    }
</script>
