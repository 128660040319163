<template>
    <v-card-text>
        <v-form v-on:submit.prevent.stop="update" autocomplete="off">
            <v-text-field
                    v-model="account.email"
                    :label="$vuetify.lang.t('$vuetify.email')"
                    type="email"
                    autocomplete="new-e-mail"
                    required
            ></v-text-field>
            <v-text-field
                    v-model="account.password"
                    :label="$vuetify.lang.t('$vuetify.password')"
                    type="password"
                    autocomplete="new-password"
                    required
            ></v-text-field>
            <v-text-field
                    v-model="account.password_confirmation"
                    :label="$vuetify.lang.t('$vuetify.passwordConfirmation')"
                    type="password"
                    autocomplete="new-password-confirmation"
                    required
            ></v-text-field>

            <v-btn v-if="loading" color="primary" disabled><v-progress-circular size="24" indeterminate></v-progress-circular></v-btn>
            <v-btn v-else type="submit" color="primary">{{ $vuetify.lang.t('$vuetify.save') }}</v-btn>
        </v-form>
    </v-card-text>
</template>

<script>

    export default {
        name: 'Account',
        components: {

        },
        data: () => ({
            loading: false,
            account: {
                email: null,
                password: null,
                password_confirmation: null
            }

        }),
        created() {
            this.setEmail()
        },

        methods: {

            setEmail(){
                this.account.email=this.$root.user.email;
            },

            update(){
                this.loading=true;
                this.$http.put('/api/account', this.account)
                    .then(({data}) => {
                        this.$root.noty = data.messages || [];
                    }).catch(error => this.$root.notyError(error.response)).finally(()=>{this.loading=false});
            }
        }
    }
</script>
