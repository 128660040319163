import Vue from 'vue';
import Vuetify from 'vuetify/lib';

import de from '../locale/de.ts';
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'mdi',
    },
    lang: {
        locales: { de },
        current: 'de',
    },
    theme: {
        themes: {
            light: {
                primary: colors.shades.black
            }
        }
    }
});
