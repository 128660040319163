<template>
    <v-card
            flat
            tile
    >
        <v-progress-linear v-if="loading" indeterminate></v-progress-linear>
        <v-card-title>
            <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    :label="$vuetify.lang.t('$vuetify.search')"
                    single-line
                    hide-details
            ></v-text-field>
            <v-spacer></v-spacer>

            <v-tooltip left>
                <template v-slot:activator="{ on }">
                    <v-btn
                            v-on="on"
                            small
                            fab
                            color="green"
                            class="mr-1"
                            @click="accept"
                    ><v-icon color="white">mdi-check-circle</v-icon></v-btn>
                </template>
                <span>{{ $vuetify.lang.t('$vuetify.tooltipApplicationAccept') }}</span>
            </v-tooltip>

            <v-tooltip left>
                <template v-slot:activator="{ on }">
                    <v-btn
                            v-on="on"
                            small
                            fab
                            color="error"
                            class="mr-3"
                            @click="decline"
                    ><v-icon>mdi-close-circle</v-icon></v-btn>
                </template>
                <span>{{ $vuetify.lang.t('$vuetify.tooltipApplicationDecline') }}</span>
            </v-tooltip>

            <v-tooltip left>
                <template v-slot:activator="{ on }">
                    <v-btn
                            v-on="on"
                            small
                            fab
                            color="primary"
                            class="mr-1"
                            :href="$root.appUrl+'api/trips/'+$route.params.id+'/applications/export?api_token='+$root.user.api_token"
                            target="_blank"
                    ><v-icon>mdi-cloud-download</v-icon></v-btn>
                </template>
                <span>{{ $vuetify.lang.t('$vuetify.tooltipApplicationDownload') }}</span>
            </v-tooltip>

            <v-dialog
                    v-model="importDialog"
                    width="500"
            >

                <template v-slot:activator="{ on: dialog }">
                    <v-tooltip left>
                        <template v-slot:activator="{ on: tooltip }">
                            <v-btn
                                    v-on="{ ...dialog, ...tooltip }"
                                    fab
                                    small
                                    color="primary"
                            ><v-icon>mdi-cloud-upload</v-icon></v-btn>
                        </template>
                        <span>{{ $vuetify.lang.t('$vuetify.tooltipApplicationUpload') }}</span>
                    </v-tooltip>
                </template>

                <v-card>
                    <v-card-title
                            primary-title
                    >
                        {{ $vuetify.lang.t('$vuetify.importApplications') }}
                    </v-card-title>

                    <v-card-text>
                        <v-file-input
                                :label="$vuetify.lang.t('$vuetify.chooseFile')"
                                v-model="importFile"
                        ></v-file-input>

                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                                color="primary"
                                text
                                @click="importDialog = false"
                        >
                            {{ $vuetify.lang.t('$vuetify.close') }}
                        </v-btn>
                        <v-btn
                                color="primary"
                                @click="importApplications"
                        >
                            {{ $vuetify.lang.t('$vuetify.import') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-card-title>

        <v-data-table
                :headers="headers"
                :items="items"
                :search="search"
                :items-per-page="50"
                :footer-props="{
                    'items-per-page-options': [10, 50, 250, -1]
                }"
                show-select
                v-model="selected"
        >
            <template v-slot:header.approved_number="">
                {{ $vuetify.lang.t('$vuetify.nr') }}
            </template>
            <template v-slot:header.user.email="">
                {{ $vuetify.lang.t('$vuetify.email') }}
            </template>
            <template v-slot:header.members="">
                {{ $vuetify.lang.t('$vuetify.members') }}
            </template>

            <template v-slot:item.members="{ item }">
                {{ item.members.length }}
            </template>

            <template v-slot:item.status="{ item }">
                <v-icon v-if="item.approved_at" color="success">mdi-check-circle</v-icon>
                <v-icon v-else color="error">mdi-close-circle</v-icon>
            </template>

            <template v-slot:item.action="{ item }">

                <v-tooltip left>
                    <template v-slot:activator="{ on }">
                        <v-btn
                                v-on="on"
                                small
                                fab
                                @click="notify(item.id)"
                                class="mr-2"
                        ><v-icon>mdi-email</v-icon></v-btn>
                    </template>
                    <span>{{ $vuetify.lang.t('$vuetify.tooltipNotify') }}</span>
                </v-tooltip>

                <v-tooltip left>
                    <template v-slot:activator="{ on }">
                        <v-btn
                                v-on="on"
                                small
                                fab
                                color="primary"
                                @click="edit=item"
                        ><v-icon>mdi-pencil</v-icon></v-btn>
                    </template>
                    <span>{{ $vuetify.lang.t('$vuetify.tooltipEdit') }}</span>
                </v-tooltip>
            </template>

            <v-alert slot="no-results" :value="true" color="error" icon="warning">
                {{ $vuetify.lang.t('$vuetify.dataIterator.noResultsText') }}
            </v-alert>
        </v-data-table>
        <edit-user v-if="edit" :application="edit"></edit-user>
    </v-card>
</template>

<script>
    import editUser from './components/editDialog';

    export default {
        name: 'Applications',
        components: {
            editUser
        },
        data: () => ({
            items: [],
            selected: [],
            loading: false,
            search: null,
            headers: [
                {text: 'User', align: 'left', sortable: true, value: 'user.email'},
                {text: 'Mitfahrer', align: 'right', sortable: true, value: 'members'},
                {text: 'Nr', align: 'right', sortable: true, value: 'approved_number'},
                {text: 'Status', align: 'center', sortable: true, value: 'status'},
                {text: '', align: 'right', sortable: false, value: 'action'}
            ],
            edit: false,
            importDialog: false,
            importFile: null
        }),
        created() {
            this.index();
        },

        methods: {

            index(){
                this.loading=true;
                this.$http.get('/api/trips/'+this.$route.params.id+'/applications')
                    .then(({data}) => {
                        this.items = data;
                    }).catch(error => this.$root.notyError(error.response)).finally(()=>{this.loading=false});
            },
            accept(){
                this.loading=true;

                this.$http.put('/api/trips/'+this.$route.params.id+'/applications/approve', {applications: this.selected})
                    .then(({data}) => {
                        this.$root.notys(data.messages || []);
                        this.index();

                    }).catch(error => this.$root.notyError(error.response)).finally(()=>{this.loading=false});
            },
            decline(){
                this.loading=true;

                this.$http.put('/api/trips/'+this.$route.params.id+'/applications/decline', {applications: this.selected})
                    .then(({data}) => {
                        this.$root.notys(data.messages || []);
                        this.index();

                    }).catch(error => this.$root.notyError(error.response)).finally(()=>{this.loading=false});
            },

            notify(applicationId){
                this.loading=true;

                this.$http.put('/api/trips/'+this.$route.params.id+'/applications/'+applicationId+'/notify')
                    .then(({data}) => {
                        this.$root.notys(data.messages || []);

                    }).catch(error => this.$root.notyError(error.response)).finally(()=>{this.loading=false});
            },

            importApplications(){
                this.loading=true;

                var formData = new FormData;
                formData.append('import', this.importFile);

                this.$http.post('trips/'+this.$route.params.id+'/applications/import', formData)
                    .then(({data}) => {
                        this.items = data.data;
                        this.$root.notys(data.messages || []);

                    }).finally(() => {
                    this.loading=false;
                });
            },
        }
    }
</script>
