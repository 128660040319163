<template>
        <v-navigation-drawer
                v-if="$root.user"
                v-model="drawer"
                fixed
                overflow
                app
                clipped
        >

            <v-list
                    dense
                    nav
            >
                <v-list-item
                        v-for="item in navigation"
                        :key="item.name"
                        link
                        :to="item.route"
                >
                    <v-list-item-icon>
                        <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{ $vuetify.lang.t('$vuetify.navigation.'+item.name) }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>

            <template v-slot:append>
                <div class="pa-2">
                    <v-btn block v-on:click="$root.logout">Logout</v-btn>
                </div>
            </template>
        </v-navigation-drawer>
</template>

<script>
    export default {
        name: 'Sidebar',
        props:{
            drawer: [Boolean]
        },
        data: () => ({
            navigation: [
                {
                    name: 'clubs',
                    route : '/clubs',
                    icon : ''
                },
                {
                    name: 'members',
                    route : '/members',
                    icon : ''
                },
                {
                    name: 'users',
                    route : '/users',
                    icon : ''
                },
                {
                    name: 'trips',
                    route : '/trips',
                    icon : ''
                },
                {
                    name: 'settings',
                    route : '/settings',
                    icon : ''
                }
            ]
        })
    }
</script>
