<template>
    <v-dialog v-model="dialog" max-width="500px">
        <v-card>
            <v-card-title>
                <span class="headline">{{ $vuetify.lang.t('$vuetify.editTicket') }}</span>
            </v-card-title>
            <v-card-text>
                <v-text-field
                        :label="$vuetify.lang.t('$vuetify.name')"
                        v-model="ticket.name"
                ></v-text-field>
                <v-text-field
                        :label="$vuetify.lang.t('$vuetify.price')"
                        v-model="ticket.price"
                        type="number"
                ></v-text-field>
                <v-text-field
                        :label="$vuetify.lang.t('$vuetify.priceMember')"
                        v-model="ticket.price_member"
                        type="number"
                ></v-text-field>
            </v-card-text>
            <v-card-actions>
                <v-btn v-if="loadingDestroy" color="error" disabled><v-progress-circular size="24" indeterminate></v-progress-circular></v-btn>
                <v-tooltip top v-else>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" color="error" @click="destroy" :disabled="loadingDestroyLogo || loadingUpdate"><v-icon>mdi-delete</v-icon></v-btn>
                    </template>
                    <span>{{ $vuetify.lang.t('$vuetify.tooltipDestroy') }}</span>
                </v-tooltip>

                <v-spacer></v-spacer>
                <v-btn color="primary darken-1" text @click="close" :disabled="loadingUpdate || loadingDestroyLogo || loadingDestroy">{{ $vuetify.lang.t('$vuetify.close') }}</v-btn>
                <v-btn v-if="loadingUpdate" color="primary" disabled><v-progress-circular size="24" indeterminate></v-progress-circular></v-btn>
                <v-btn v-else type="submit" color="primary" @click="update" :disabled="loadingDestroyLogo || loadingDestroy">{{ $vuetify.lang.t('$vuetify.save') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        name: 'editDialog',
        props:{
            ticket: Object
        },
        data: () => ({
            loadingUpdate: false,
            loadingDestroy: false,
            loadingDestroyLogo: false,
            dialog: true,
        }),

        methods: {

            update(){
                this.loadingUpdate=true;

                this.$http.put('/api/trips/'+this.$route.params.id+'/tickets/'+this.ticket.id, this.ticket)
                    .then(({data}) => {
                        this.$root.notys(data.messages || []);
                        this.ticket.name = data.data.name;
                        this.ticket.price = data.data.price;
                        this.ticket.price_member = data.data.price_member;
                    }).catch(error => this.$root.notyError(error.response)).finally(()=>{this.loadingUpdate=false});
            },
            destroy(){
                if(confirm(this.$vuetify.lang.t('$vuetify.destroy'))){
                    this.loadingDestroy=true;
                    this.$http.delete('/api/trips/'+this.$route.params.id+'/tickets/'+this.ticket.id)
                        .then(({data}) => {
                            this.$root.notys(data.messages || []);
                            this.$parent.$parent.index();
                            this.close();
                        }).catch(error => this.$root.notyError(error.response)).finally(()=>{this.loadingDestroy=false});
                }
            },

            close(){
                this.$parent.$parent.edit=false;
                this.dialog = false;
            }
        }
    }
</script>
