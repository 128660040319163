<template>
  <v-app>
    <sidebar :drawer="drawer"></sidebar>
    <v-app-bar clipped-left app fixed v-if="$root.user">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title class="headline text-uppercase">
        <span>BVB Buchungsportal</span>
      </v-toolbar-title>
    </v-app-bar>

    <v-content>
      <v-container fluid grid-list-md>
        <router-view></router-view>
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
    import 'vuejs-noty/dist/vuejs-noty.css'
    import Sidebar from './components/sidebar';

    export default {
        name: 'App',
        components: {
            Sidebar,
        },
        data: () => ({
            drawer: null
        }),
    };
</script>

<style>
  .draggable{
    cursor: grab;
  }

  .draggable.dragging{
    cursor: grabbing;
  }


</style>
