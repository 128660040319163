<template>
    <v-dialog v-model="dialog" max-width="500px">
        <v-card>
            <v-card-title>
                <span class="headline">{{ $vuetify.lang.t('$vuetify.editClub') }}</span>
            </v-card-title>
            <v-card-text>
                <v-text-field
                        :label="$vuetify.lang.t('$vuetify.name')"
                        v-model="club.name"
                ></v-text-field>
                <v-text-field
                        :label="$vuetify.lang.t('$vuetify.stadium')"
                        v-model="club.stadium"
                ></v-text-field>
                <v-img
                        v-if="typeof club.logo === 'string'"
                        :src="$root.appUrl+'storage/clubs/'+club.logo"
                ></v-img>
                <v-file-input
                        v-else
                        :label="$vuetify.lang.t('$vuetify.uploadLogo')"
                        v-model="club.logo"
                ></v-file-input>
            </v-card-text>
            <v-card-actions>
                <v-btn v-if="loadingDestroy" color="error" disabled><v-progress-circular size="24" indeterminate></v-progress-circular></v-btn>
                <v-tooltip top v-else>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" color="error" @click="destroy" :disabled="loadingDestroyLogo || loadingUpdate"><v-icon>mdi-delete</v-icon></v-btn>
                    </template>
                    <span>{{ $vuetify.lang.t('$vuetify.tooltipDestroy') }}</span>
                </v-tooltip>

                <template v-if="typeof club.logo === 'string'">
                    <v-btn v-if="loadingDestroyLogo" color="error" disabled class="ml-2"><v-progress-circular size="24" indeterminate></v-progress-circular></v-btn>
                    <v-tooltip top v-else>
                        <template v-slot:activator="{ on }">
                            <v-btn v-on="on" color="error" @click="destroyLogo" :disabled="loadingUpdate || loadingDestroy" class="ml-2"><v-icon>mdi-image</v-icon><v-icon>mdi-delete</v-icon></v-btn>
                        </template>
                        <span>{{ $vuetify.lang.t('$vuetify.tooltipDestroyImage') }}</span>
                    </v-tooltip>
                </template>

                <v-spacer></v-spacer>
                <v-btn color="primary darken-1" text @click="close" :disabled="loadingUpdate || loadingDestroyLogo || loadingDestroy">{{ $vuetify.lang.t('$vuetify.close') }}</v-btn>
                <v-btn v-if="loadingUpdate" color="primary" disabled><v-progress-circular size="24" indeterminate></v-progress-circular></v-btn>
                <v-btn v-else type="submit" color="primary" @click="update" :disabled="loadingDestroyLogo || loadingDestroy">{{ $vuetify.lang.t('$vuetify.save') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        name: 'editDialog',
        props:{
            club: Object
        },
        data: () => ({
            loadingUpdate: false,
            loadingDestroy: false,
            loadingDestroyLogo: false,
            dialog: true,
        }),

        methods: {

            update(){
                this.loadingUpdate=true;

                var data = new FormData;
                data.append('name', this.club.name);
                data.append('stadium', this.club.stadium);
                if(typeof this.club.logo === 'object'){
                    if(this.club.logo!=null){
                        data.append('logo', this.club.logo);
                    }
                }
                data.append('_method', 'PUT');

                this.$http.post('/api/clubs/'+this.club.id, data, {headers: {'Content-Type': 'multipart/form-data'}})
                    .then(({data}) => {
                        this.$root.notys(data.messages || []);
                        this.club.name = data.data.name;
                        this.club.stadium = data.data.stadium;
                        this.club.logo = data.data.logo;
                    }).catch(error => this.$root.notyError(error.response)).finally(()=>{this.loadingUpdate=false});
            },
            destroy(){
                if(confirm(this.$vuetify.lang.t('$vuetify.destroy'))){
                    this.loadingDestroy=true;
                    this.$http.delete('/api/clubs/'+this.club.id)
                        .then(({data}) => {
                            this.$root.notys(data.messages || []);
                            this.$parent.$parent.index();
                            this.close();
                        }).catch(error => this.$root.notyError(error.response)).finally(()=>{this.loadingDestroy=false});
                }
            },
            destroyLogo(){
                if(confirm(this.$vuetify.lang.t('$vuetify.destroy'))){
                    this.loadingDestroyLogo=true;
                    this.$http.delete('/api/clubs/'+this.club.id+'/logo', {name: this.club.name})
                        .then(({data}) => {
                            this.$root.notys(data.messages || []);
                            this.club.logo = null;
                        }).catch(error => this.$root.notyError(error.response)).finally(()=>{this.loadingDestroyLogo=false});
                }
            },

            close(){
                this.$parent.$parent.edit=false;
                this.dialog = false;
            }
        }
    }
</script>
