<template>
    <v-dialog v-model="dialog" max-width="500px">

        <template v-slot:activator="{ on: dialog }">
            <v-tooltip left>
                <template v-slot:activator="{ on: tooltip }">
                    <v-btn
                            v-on="{ ...dialog, ...tooltip }"
                            fab
                            small
                            color="primary"
                            class="mr-1"
                    >
                        <v-icon>mdi-upload</v-icon>
                    </v-btn>
                </template>
                <span>
                {{ $vuetify.lang.t('$vuetify.tooltipUploadMembers') }}
            </span>
            </v-tooltip>
        </template>
        <v-card>
            <v-card-title>
                <span class="headline">{{ $vuetify.lang.t('$vuetify.uploadMembers') }}</span>
            </v-card-title>
            <v-card-text>
                <v-file-input
                        :label="$vuetify.lang.t('$vuetify.chooseFile')"
                        v-model="file"
                ></v-file-input>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary darken-1" text @click="close" :disabled="loading">{{ $vuetify.lang.t('$vuetify.close') }}</v-btn>
                <v-btn v-if="loading" color="primary" disabled><v-progress-circular size="24" indeterminate></v-progress-circular></v-btn>
                <v-btn v-else type="submit" color="primary" @click="store">{{ $vuetify.lang.t('$vuetify.save') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        name: 'createDialog',
        data: () => ({
            loading: false,
            dialog: false,
            file: null
        }),

        methods: {

            store(){
                this.loading=true;

                var data = new FormData;
                data.append('file', this.file);

                this.$http.post('/api/members/upload', data, {headers: {'Content-Type': 'multipart/form-data'}})
                    .then(({data}) => {
                        this.$parent.$parent.index();
                        this.$root.notys(data.messages);
                        this.close();
                    }).catch(error => this.$root.notyError(error.response)).finally(()=>{this.loading=false});
            },

            close(){
                this.file=null;
                this.dialog=false;
            }
        }
    }
</script>
