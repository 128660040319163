<template>
    <v-card>
        <v-progress-linear v-if="loading" indeterminate></v-progress-linear>
        <v-simple-table>
            <template v-slot:default>
                <thead>
                <tr>
                    <th>{{ $vuetify.lang.t('$vuetify.email') }}</th>
                    <th class="text-right">
                        <create-admin></create-admin>
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in items" v-bind:key="item.id">
                    <td>{{item.email}}</td>
                    <td class="text-right">
                        <v-tooltip left>
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on" v-if="item.email !== $root.user.email" color="error" fab small @click="destroy(item.id)"><v-icon>mdi-delete</v-icon></v-btn>

                            </template>
                            <span>{{ $vuetify.lang.t('$vuetify.tooltipDestroy') }}</span>
                        </v-tooltip>
                    </td>
                </tr>
                </tbody>
            </template>
        </v-simple-table>
    </v-card>
</template>

<script>
    import createAdmin from './components/createDialog';

    export default {
        name: 'Admins',
        components: {
            createAdmin
        },
        data: () => ({
            items: [],
            loading: false
        }),
        created() {
            this.index();
        },

        methods: {

            index(){
                this.loading=true;
                this.$http.get('/api/admins')
                    .then(({data}) => {
                        this.items = data;
                    }).catch(error => this.$root.notyError(error.response)).finally(()=>{this.loading=false});
            },
            destroy(adminId){
                if(confirm(this.$vuetify.lang.t('$vuetify.destroy'))){
                    this.loading=true;
                    this.$http.delete('/api/admins/'+adminId)
                        .then(({data}) => {
                            this.$root.notys(data.messages || []);
                            this.index();
                        }).catch(error => this.$root.notyError(error.response)).finally(()=>{this.loading=false});
                }
            },
        }
    }
</script>
