<template>
    <v-dialog v-model="dialog" max-width="500px">
        <template v-slot:activator="{ on: dialog }">
            <v-tooltip left>
                <template v-slot:activator="{ on: tooltip }">
                    <v-btn
                            v-on="{ ...dialog, ...tooltip }"
                            fab
                            small
                            color="primary"
                    ><v-icon>mdi-plus</v-icon></v-btn>
                </template>
                <span>{{ $vuetify.lang.t('$vuetify.tooltipCreateAdmin') }}</span>
            </v-tooltip>
        </template>

        <v-card>
            <v-card-title>
                <span class="headline">{{ $vuetify.lang.t('$vuetify.createAdmin') }}</span>
            </v-card-title>
            <v-card-text>
                <v-text-field
                        :label="$vuetify.lang.t('$vuetify.email')"
                        v-model="admin.email"
                ></v-text-field>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary darken-1" text @click="close">{{ $vuetify.lang.t('$vuetify.close') }}</v-btn>
                <v-btn v-if="loading" color="primary" disabled><v-progress-circular size="24" indeterminate></v-progress-circular></v-btn>
                <v-btn v-else type="submit" color="primary" @click="store">{{ $vuetify.lang.t('$vuetify.save') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        name: 'createDialog',
        data: () => ({
            loading: false,
            dialog: false,
            admin: {
                email: null
            }
        }),

        methods: {
            store(){
                this.loading=true;
                this.$http.post('/api/admins', this.admin)
                    .then(({data}) => {
                        this.$root.notys(data.messages || []);
                        this.$parent.$parent.$parent.index();
                        this.close();
                    }).catch(error => this.$root.notyError(error.response)).finally(()=>{this.loading=false});
            },

            close(){
                this.admin.email=null;
                this.dialog=false;
            }
        }
    }
</script>
