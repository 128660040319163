<template>
    <v-card>
        <v-progress-linear v-if="loading" indeterminate></v-progress-linear>
        <v-card-title>
            <span>{{ $vuetify.lang.t('$vuetify.members') }}</span>
            <v-spacer></v-spacer>
            <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    :label="$vuetify.lang.t('$vuetify.search')"
                    single-line
                    hide-details
            ></v-text-field>
            <v-spacer></v-spacer>
            <upload-member></upload-member>
            <create-member></create-member>
        </v-card-title>

        <v-data-table
                :headers="headers"
                :items="items"
                :search="search"
                :items-per-page="50"
                :footer-props="{
                    'items-per-page-options': [10, 50, 250, -1]
                }"
        >
            <template v-slot:header.member_no="">
                {{ $vuetify.lang.t('$vuetify.member_no') }}
            </template>
            <template v-slot:header.name="">
                {{ $vuetify.lang.t('$vuetify.name') }}
            </template>
            <template v-slot:header.address="">
                {{ $vuetify.lang.t('$vuetify.address') }}
            </template>
            <template v-slot:header.city="">
                {{ $vuetify.lang.t('$vuetify.postal_code') }}
            </template>
            <template v-slot:header.city="">
                {{ $vuetify.lang.t('$vuetify.city') }}
            </template>
            <template v-slot:header.birthday="">
                {{ $vuetify.lang.t('$vuetify.birthday') }}
            </template>

            <template v-slot:item.action="{ item }">

                <v-tooltip left>
                    <template v-slot:activator="{ on }">
                        <v-btn
                                v-on="on"
                                small
                                fab
                                color="primary"
                                @click="edit=item"
                        ><v-icon>mdi-pencil</v-icon></v-btn>
                    </template>
                    <span>{{ $vuetify.lang.t('$vuetify.tooltipEdit') }}</span>
                </v-tooltip>

            </template>
            <v-alert slot="no-results" :value="true" color="error" icon="warning">
                {{ $vuetify.lang.t('$vuetify.dataIterator.noResultsText') }}
            </v-alert>
        </v-data-table>
        <edit-member v-if="edit" :member="edit"></edit-member>
    </v-card>
</template>

<script>
    import createMember from './components/createDialog';
    import editMember from './components/editDialog';
    import uploadMember from './components/uploadDialog';


    export default {
        name: 'Members',
        components: {
            createMember,
            editMember,
            uploadMember
        },
        data: () => ({
            items: [],
            loading: false,
            search: null,
            headers: [
                {text: '#', align: 'left', sortable: true, value: 'member_no'},
                {text: 'Name', align: 'left', sortable: true, value: 'name'},
                {text: 'Adresse', align: 'left', sortable: true, value: 'address'},
                {text: 'Postleitzahl', align: 'left', sortable: true, value: 'postal_code'},
                {text: 'Ort', align: 'left', sortable: true, value: 'city'},
                {text: 'Geburtsdatum', align: 'left', sortable: true, value: 'birthday'},
                {text: '', align: 'right', sortable: false, value: 'action'}
            ],
            edit: false
        }),
        created() {
            this.index();
        },

        methods: {

            index(){
                this.loading=true;
                this.$http.get('/api/members')
                    .then(({data}) => {
                        this.items = data;
                    }).catch(error => this.$root.notyError(error.response)).finally(()=>{this.loading=false});
            }
        }
    }
</script>
