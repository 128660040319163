<template>
    <v-dialog v-model="dialog" max-width="500px">
        <template v-slot:activator="{ on }">
            <v-btn v-on="on" color="primary darken-1" text>{{ $vuetify.lang.t('$vuetify.forgetPassword') }}</v-btn>
        </template>
        <v-card>
            <v-card-title>
                <span class="headline">{{ $vuetify.lang.t('$vuetify.forgetPassword') }}</span>
            </v-card-title>
            <v-card-text>
                <p>{{ $vuetify.lang.t('$vuetify.forgetPasswordHelpText') }}</p>
                <v-text-field
                        :label="$vuetify.lang.t('$vuetify.email')"
                        v-model="email"
                ></v-text-field>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary darken-1" text @click="close">{{ $vuetify.lang.t('$vuetify.close') }}</v-btn>
                <v-btn v-if="loading" color="primary" disabled><v-progress-circular size="24" indeterminate></v-progress-circular></v-btn>
                <v-btn v-else type="submit" color="primary" @click="store">{{ $vuetify.lang.t('$vuetify.requestPassword') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        name: 'forgetPasswordDialog',
        data: () => ({
            loading: false,
            dialog: false,
            email: null
        }),

        methods: {
            store(){
                this.loading=true;
                this.$http.post('/api/password/email', {email: this.email})
                    .then(({data}) => {
                        this.$root.notys(data.messages || []);
                    }).catch(error => this.$root.notyError(error.response)).finally(()=>{this.loading=false});
            },

            close(){
                this.email=null;
                this.dialog=false;
            }
        }
    }
</script>
