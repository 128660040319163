<template>
    <v-card
            flat
            tile
    >
        <v-card-text>
            <v-row>
                <v-col>
                    <editor :text="trip.description" v-on:edit="editText"></editor>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions>

            <v-spacer></v-spacer>
            <v-btn v-if="loading" color="primary" disabled><v-progress-circular size="24" indeterminate></v-progress-circular></v-btn>
            <v-btn v-else type="submit" color="primary" @click="update">{{ $vuetify.lang.t('$vuetify.save') }}</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import editor from 'vue2-medium-editor'

    export default {
        name: 'Description',
        components: {
            editor
        },
        data: () => ({
            trip: {
                description: null
            },
            loading: false
        }),
        created() {
            this.show();
        },

        methods: {

            show(){
                this.loading=true;
                this.$http.get('/api/trips/'+this.$route.params.id)
                    .then(({data}) => {
                        this.trip = data;
                    }).catch(error => this.$root.notyError(error.response)).finally(()=>{this.loading=false});
            },
            update(){
                this.loading=true;

                this.$http.put('/api/trips/'+this.$route.params.id, this.trip)
                    .then(({data}) => {
                        this.trip = data.data;
                    }).catch(error => this.$root.notyError(error.response)).finally(()=>{this.loading=false});
            },
            editText: function (operation) {
                this.trip.description = operation.api.origElements.innerHTML
            }
        }
    }
</script>

<style lang="css">
    @import "~medium-editor/dist/css/medium-editor.min.css";
</style>
